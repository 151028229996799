import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SharedService } from './shared.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class HttpService {
  private headers;
  private getHeader;
  private postHeader;
  // private HostURL = "http://localhost:3000";
  private HostURL = "  https://ava-mersus-analytics-v2.herokuapp.com"

  constructor(
    private http: HttpClient,
    private router: Router,
    private _sharedService: SharedService
  ) {
    const token = localStorage.getItem('token');
    if (token) {
      this.setHeaders(token);
    }
  }

  setHeaders(token: string): void {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    this.getHeader = { headers: this.headers, withCredentials: true };
    this.postHeader = { headers: this.headers, withCredentials: true };
  }

  //#region SingleApp
  public getSADashboardData(org): Observable<any> {
    const endpoint = `/SA/Dashboard/Data`;
    return this.http.post(this.HostURL + endpoint, org, this.postHeader);
  }

  public getSACourses(org): Observable<any> {
    const endpoint = `/SA/Courses`;
    return this.http.post(this.HostURL + endpoint, org, this.postHeader);
  }

  public getSAModuleData(projectCode): Observable<any> {
    const endpoint = `/SA/Course/Modules/Data`;
    return this.http.post(this.HostURL + endpoint, projectCode, this.postHeader);
  }

  public getSATraineesList(org): Observable<any> {
    const endpoint = `/SA/Trainees/Table`;
    return this.http.post(this.HostURL + endpoint, org, this.postHeader);
  }

  public getSATraineesCount(org): Observable<any> {
    const endpoint = `/SA/Trainees/Count`;
    return this.http.post(this.HostURL + endpoint, org, this.postHeader);
  }

  public getSATraineesModuleStatus(projectCode: string, userID: string): Observable<any> {
    const endpoint = `/SA/Trainees/User`;
    const body = { "org": projectCode, "userID": userID };
    return this.http.post(this.HostURL + endpoint, body, this.postHeader);
  }

  public getSATraineesModuleDetails(projectCode: string, userID: string, selectedModuleName: string, selectedCourseID: string): Observable<any> {
    const endpoint = `/SA/Trainees/User/Module`;
    const body = {
      "org": projectCode,
      "courseID": selectedCourseID
    };
    return this.http.post(this.HostURL + endpoint, body, this.postHeader)
      .pipe(
        switchMap((response: any) => {
          const newProjectCode = response;
          return this.getTraineesModuleDetails(newProjectCode, userID, selectedModuleName);
        })
      );
  }
  public getSATraineesModuleOverview(selectedCourseID: string, selectedModuleName: string, userID: string): Observable<any> {
    const endpoint = `/SA/Trainees/User/Module/Overview`;
    const body = {
      "courseID": selectedCourseID,
      "moduleName": selectedModuleName,
      "userID": userID,
    }
    return this.http.post(this.HostURL + endpoint, body, this.postHeader);
  }

  public getSALeaderboardData(body): Observable<any> {
    const endpoint = `/SA/Leaderboard/Data`;
    return this.http.post(this.HostURL + endpoint, body, this.postHeader);
  }

  public getSAModuleStats(body): Observable<any> {
    const endpoint = `/SA/Leaderboard/ModuleStatistics`;
    return this.http.post(this.HostURL + endpoint, body, this.postHeader);
  }

  public addSATrainee(traineeData): Observable<any> {
    return this.http.post(this.HostURL + "/SA/Register/Trainee", traineeData, this.postHeader);
  }

  public registerSATrainee(traineeData): Observable<any> {
    return this.http.post(this.HostURL + "/SA/Register/Trainee/Data", traineeData, this.postHeader);
  }

  public sendSATraineeAcknowledgement(traineeDetails): Observable<any> {
    return this.http.post(this.HostURL + "/SA/Trainee/Acknowledge", traineeDetails, this.postHeader);
  }

  public checkSAUsername(data): Observable<any> {
    return this.http.post(this.HostURL + "/SA/CheckUsername", data, this.postHeader);
  }

  public editSATrainee(traineeData): Observable<any> {
    return this.http.post(this.HostURL + "/SA/Trainee/Edit", traineeData, this.postHeader);
  }

  public archiveSATrainee(traineeDetails): Observable<any> {
    return this.http.post(this.HostURL + "/SA/Trainee/Archive", traineeDetails, this.postHeader);
  }

  public unarchiveSATrainee(traineeDetails): Observable<any> {
    return this.http.post(this.HostURL + "/SA/Trainee/Unarchive", traineeDetails, this.postHeader);
  } 
  
  public deleteSATrainee(traineeDetails): Observable<any> {
    return this.http.post(this.HostURL + "/SA/Trainee/Delete", traineeDetails, this.postHeader);
  }
  public webRegisterSATrainee(traineeDetails): Observable<any> {
    return this.http.post(this.HostURL + "/SA/Register/Trainee/Web", traineeDetails, this.postHeader);
  }

  public getSATraineeDetails(userID): Observable<any> {
    return this.http.post(this.HostURL + "/SA/Trainees/GetDetails", userID, this.postHeader);
  }

  public updateSATraineeDetails(traineeData): Observable<any> {
    return this.http.post(this.HostURL + "/SA/Trainees/PersonalData", traineeData, this.postHeader);
  }

  public getSALicenseData(org): Observable<any> {
    return this.http.post(this.HostURL + "/SA/Org/LicenseInfo", org, this.postHeader);
  }

  public updateSAPIN(body): Observable<any> {
    return this.http.post(this.HostURL + "/SA/Trainees/ForgotPIN", body, this.postHeader);
  }

  public addSACourses(course): Observable<any> {
    return this.http.post(this.HostURL + "/SA/Course/Create", course, this.postHeader);
  }

  public assignSACourses(course): Observable<any> {
    return this.http.post(this.HostURL + "/SA/Course/Assign", course, this.postHeader);
  }

  public deleteSACourses(courseID): Observable<any> {
    return this.http.post(this.HostURL + "/SA/Course/Delete", courseID, this.postHeader);
  }

  public addSAModules(course): Observable<any> {
    return this.http.post(this.HostURL + "/SA/Course/Module/Create", course, this.postHeader);
  }

  public updateSAModules(course): Observable<any> {
    return this.http.post(this.HostURL + "/SA/Course/Module/Update", course, this.postHeader);
  }

  public deleteSAModule(course): Observable<any> {
    return this.http.post(this.HostURL + "/SA/Course/Module/Delete", course, this.postHeader);
  }

  public updateSAModuleImage(module): Observable<any> {
    return this.http.post(this.HostURL + "/SA/Course/Module/Image/Update", module, this.postHeader);
  }

  public getSAModules(body): Observable<any> {
    return this.http.post(this.HostURL + "/SA/Course/Modules", body, this.postHeader);
  }

  public getSACourseData(body): Observable<any> {
    return this.http.post(this.HostURL + "/SA/Course/Data", body, this.postHeader);
  }

  public addSACourseData(body): Observable<any> {
    return this.http.post(this.HostURL + "/SA/Course/Data/Add", body, this.postHeader);
  }

  public updateSACourseImage(body): Observable<any> {
    return this.http.post(this.HostURL + "/SA/Course/Image/Update", body, this.postHeader);
  }
  //#endregion

  //#region Mersus
  public getDashboardDataMersusV2(): Observable<any> {
    return this.http.get(this.HostURL + "/Mersus/Dashboard/Data/V2", this.getHeader);
  }
  public getClientSessionAnalyticsV2(client): Observable<any> {
    return this.http.post(this.HostURL + "/Mersus/Clients/SessionAnalytics", client, this.postHeader);
  }
  public getClientUserAnalyticsV2(client): Observable<any> {
    return this.http.post(this.HostURL + "/Mersus/Clients/UserAnalytics", client, this.postHeader);
  }
  public forgetPassword(data): Observable<any> {
    return this.http.post(this.HostURL + "/user/forgetpassword", data, this.postHeader);
  }
  public updatePasswordV2(data): Observable<any> {
    return this.http.post(this.HostURL + "/user/forgetpassword/update", data, this.postHeader);
  }
  public getAdminsTable(client): Observable<any> {
    return this.http.post(this.HostURL + "/user/admin/table", client, this.postHeader);
  }
  public deleteAdmin(adminDetails): Observable<any> {
    return this.http.post(this.HostURL + "/user/admin/delete", adminDetails, this.postHeader);
  }
  public editAdmin(adminDetails): Observable<any> {
    return this.http.post(this.HostURL + "/user/admin/edit", adminDetails, this.postHeader);
  }
  public preLoginRequest(): Observable<any> {
    return this.http.get(this.HostURL + "/Mersus/PreLogin", this.getHeader);
  }
  public checkUsername(data): Observable<any> {
    return this.http.post(this.HostURL + "/user/checkUsername", data, this.postHeader);
  }
  public addTrainee(traineeData): Observable<any> {
    return this.http.post(this.HostURL + "/user/register/trainee", traineeData, this.postHeader);
  }
  public registerTraineeBOS(traineeData): Observable<any> {
    return this.http.post(this.HostURL + "/user/register/trainee/data", traineeData, this.postHeader);
  }
  public addAdmin(adminData): Observable<any> {
    return this.http.post(this.HostURL + "/user/create/admin", adminData, this.postHeader);
  }
  public registerAdminBOS(adminData): Observable<any> {
    return this.http.post(this.HostURL + "/user/create/admin/data", adminData, this.postHeader);
  }
  public editTrainee(traineeDetails): Observable<any> {
    return this.http.post(this.HostURL + "/user/trainee/edit", traineeDetails, this.postHeader);
  }
  public deleteTrainee(traineeDetails): Observable<any> {
    return this.http.post(this.HostURL + "/user/trainee/delete", traineeDetails, this.postHeader);
  }
  public archiveTrainee(traineeDetails): Observable<any> {
    return this.http.post(this.HostURL + "/user/trainee/archive", traineeDetails, this.postHeader);
  }
  public unarchiveTrainee(traineeDetails): Observable<any> {
    return this.http.post(this.HostURL + "/user/trainee/unarchive", traineeDetails, this.postHeader);
  }
  public webRegisterTrainee(traineeDetails): Observable<any> {
    return this.http.post(this.HostURL + "/user/register/trainee/web", traineeDetails, this.postHeader);
  }
  public sendTraineeAcknowledgement(traineeDetails): Observable<any> {
    return this.http.post(this.HostURL + "/user/trainee/acknowledge", traineeDetails, this.postHeader);
  }
  public getLogin(loginForm): Observable<any> {
    return this.http.post(this.HostURL + "/user/login", loginForm, this.postHeader);
  }
  public getLogout(): Observable<any> {
    return this.http.get(this.HostURL + "/user/logout", this.getHeader);
  }
  public getAuthentication(): Observable<any> {
    if (localStorage.getItem('token'))
      return this.http.get(this.HostURL + "/user/isAuthenticated", this.getHeader);
    else
      this.router.navigateByUrl("/Logout");
  }
  public getAvailableCourses(org): Observable<any> {
    return this.http.post(this.HostURL + "/Mersus/AvailableCourses", org, this.postHeader);
  }
  public updatePassword(passwordObject): Observable<any> {
    return this.http.put(this.HostURL + "/user/update/password", passwordObject, this.postHeader);
  }
  public verifyAccountNewVIS(empID): Observable<any> {
    return this.http.get("https://ava-mersus-fit001.herokuapp.com/VIS/CAVE/002/Verify/" + empID, this.getHeader);
  }
  public forgetPasswordUpdate(bodyData): Observable<any> {
    return this.http.post("https://ava-mersus-fit001.herokuapp.com/VIS/CAVE/002/UpdatePassword", bodyData, this.postHeader);
  }
  //#endregion

  //#region Common API Calls

  public getDashboardData(projectCode: string): Observable<any> {
    const endpoint = `/V2/${projectCode}/Dashboard/Data`;
    return this.http.get(this.HostURL + endpoint, this.getHeader);
  }

  public getCourses(projectCode: string): Observable<any> {
    const endpoint = `/V2/${projectCode}/Course/Table`;
    return this.http.get(this.HostURL + endpoint, this.getHeader);
  }

  public getModules(projectCode: string): Observable<any> {
    const endpoint = `/V2/${projectCode}/Module/Table`;
    return this.http.get(this.HostURL + endpoint, this.getHeader);
  }

  public getLeaderboardData(projectCode: string, moduleName: string): Observable<any> {
    const endpoint = `/V2/${projectCode}/Leaderboard/Data`;
    const body = { "moduleName": moduleName };
    return this.http.post(this.HostURL + endpoint, body, this.postHeader);
  }

  public getModuleStats(projectCode: string, moduleName: string): Observable<any> {
    const endpoint = `/V2/${projectCode}/Leaderboard/ModuleStatistics`;
    const body = { "moduleName": moduleName };
    return this.http.post(this.HostURL + endpoint, body, this.postHeader);
  }

  public getActivity(projectCode: string): Observable<any> {
    const endpoint = `/V2/${projectCode}/Activity/Table`;
    return this.http.get(this.HostURL + endpoint, this.getHeader);
  }

  public getTraineesList(projectCode: string): Observable<any> {
    const endpoint = `/V2/${projectCode}/Trainees/Table`;
    return this.http.get(this.HostURL + endpoint, this.getHeader);
  }

  public getTraineesModuleStatus(projectCode: string, userID: string): Observable<any> {
    const endpoint = `/V2/${projectCode}/Trainees/User`;
    const body = { "userID": userID };
    return this.http.post(this.HostURL + endpoint, body, this.postHeader);
  }

  public getTraineesModuleDetails(projectCode: string, userID: string, selectedModuleName: string): Observable<any> {
    const endpoint = `/V2/${projectCode}/Trainees/User/Module`;
    const body = {
      "moduleName": selectedModuleName,
      "userID": userID
    }
    return this.http.post(this.HostURL + endpoint, body, this.postHeader);
  }

  public getTraineesModuleOverview(projectCode: string, userID: string, selectedModuleName: string): Observable<any> {
    const endpoint = `/V2/${projectCode}/Trainees/User/Module/Overview`;
    const body = {
      "moduleName": selectedModuleName,
      "userID": userID
    }
    return this.http.post(this.HostURL + endpoint, body, this.postHeader);
  }

  public getLicenseData(projectCode: string): Observable<any> {
    const endpoint = `/V2/${projectCode}/Trainees/LicenseInfo`;
    return this.http.get(this.HostURL + endpoint, this.getHeader);
  }

  //#region User Management Stuff
  public getTraineesCount(projectCode: string): Observable<any> {
    const endpoint = `/V2/${projectCode}/Trainees/Count`;
    return this.http.get(this.HostURL + endpoint, this.getHeader);
  }

  public updateTraineeDetails(projectCode: string, traineeData: {
    userID: string | null;
    oldpwd: string | null;
    newpwd: string | null;
    oldPIN: string | null;
    newPIN: string | null;
    userName: string | null;
    gender: string | null;
    age: number | null;
  }): Observable<any> {
    const endpoint = `/V2/${projectCode}/Trainees/PersonalData`;
    return this.http.post(this.HostURL + endpoint, traineeData, this.postHeader);
  }

  public getTraineeDetails(projectCode: string, userID: string): Observable<any> {
    const endpoint = `/V2/${projectCode}/Trainees/GetDetails`;
    const body = { "userID": userID };
    return this.http.post(this.HostURL + endpoint, body, this.postHeader);
  }

  public updatePINV2(projectCode: string, userID: string, pin: string): Observable<any> {
    const endpoint = `/V2/${projectCode}/Trainees/ForgotPIN`;
    const body = {
      "pin": pin,
      "userID": userID
    }
    return this.http.post(this.HostURL + endpoint, body, this.postHeader);
  }
  //#endregion
  //#endregion

  //#region Public SA
  public registerSAUser(userData): Observable<any> {
    return this.http.post(this.HostURL + "/user/register/data", userData, this.postHeader);
  }
  //#endregion

  //#region Imtech
  //Get Quiz data
  public getQuizDataImtech(user): Observable<any> {
    return this.http.post(this.HostURL + "/V2/IMT/Quiz/Data", user, this.postHeader);
  }
  public getQuizDataImtechLCETB(user): Observable<any> {
    return this.http.post(this.HostURL + "/V2/IMTLCETB/Quiz/Data", user, this.postHeader);
  }
  //#endregion
}
