<app-main-nav>
  <div fxLayout="row" fxLayoutAlign="center" *ngIf="showAddAdminPanel && !viewAdminPanel" class="lpopup"
    [ngClass.lt-lg]="'popup-smallScreen'">
    <div fxLayout="column" fxLayoutGap="10%" fxFlex="99">
      <span class="med-heading-text-config" style="margin-right:auto;">Enter admin details</span>
      <form [formGroup]="personalForm" (ngSubmit)="addAdmin()" validate fxLayout="column">
        <mat-label class="non-floating-label"> Email address </mat-label>
        <mat-form-field floatLabel="always">
          <div class="input-field">
            <input class="input-text" autofocus matInput type="text" formControlName="email">
          </div>
        </mat-form-field>
        <span *ngIf="personalForm.controls.email.touched && personalForm.controls.email.hasError('email')"
          class="error-message">Enter a valid email address.</span>
        <mat-label class="non-floating-label"> First Name </mat-label>
        <mat-form-field floatLabel="always">
          <div class="input-field">
            <input class="input-text" matInput type="text" formControlName="fname">
          </div>
        </mat-form-field>
        <mat-label class="non-floating-label"> Surname</mat-label>
        <mat-form-field floatLabel="always">
          <div class="input-field">
            <input class="input-text" matInput type="text" formControlName="lname">
          </div>
        </mat-form-field>
        <button class="button" mat-raised-button type="submit" [disabled]="this.personalForm.invalid">SEND
          INVITATION</button>
      </form>
      <div fxLayout="row" *ngIf="inviteSent">
        <img src="/assets/2.0/AVA_tick_icon.svg" fxFlex="10%" style="height: 20px;">
        <span class="small-text-config"> Invitation sent to {{personalForm.controls['email'].value}}</span>
      </div>
      <span class="small-text-config" *ngIf="existsError"> {{errorToShow}} </span>
    </div>
    <img src="/assets/2.0/AVA_close_icon.svg"
      style="z-index: 1; height: 50px; position:relative; margin: -9% -23% 1% -5%; cursor:pointer;"
      (click)="closePopup()">
  </div>

  <div fxLayout="row" fxLayoutAlign="center" *ngIf="viewAdminPanel && !showAddAdminPanel" class="wpopup"
    [ngClass.lt-lg]="'popup-smallScreen1'">
    <div fxLayout="column" fxLayoutGap="5%" fxFlex="99">
      <span class="med-heading-text-config" style="margin-right:auto;">Administrators</span>
      <div class="table-config" style="width: 100%;">
        <mat-table [dataSource]="dataSource" style="width: 100%;" [ngClass.lt-lg]="'popup-smallScreen1'">
          <ng-container matColumnDef="Name">
            <mat-header-cell class="table-header-font" *matHeaderCellDef> Name </mat-header-cell>
            <mat-cell class="table-data-font" *matCellDef="let row" [contentEditable]="rowID === row.userID"
              (input)="onNameChange($event.target.innerHTML)"> {{row.Name}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Email">
            <mat-header-cell class="table-header-font" *matHeaderCellDef> Email </mat-header-cell>
            <mat-cell class="table-data-font" *matCellDef="let row" (input)="onEmailChange($event.target.innerHTML)">
              {{row.Email}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Operations">
            <mat-header-cell class="table-header-font" *matHeaderCellDef> Operations </mat-header-cell>
            <mat-cell class="table-data-font" *matCellDef="let row">
              <button mat-button (click)="makeEditable(row)" *ngIf="!fadeButton"><mat-icon>create</mat-icon></button>
              <button mat-button (click)="editRow(row)" *ngIf="fadeButton"><mat-icon>done_outline</mat-icon></button>
              <button mat-button (click)="deleteRow(row)" [disabled]="fadeButton"><mat-icon>delete</mat-icon></button>
            </mat-cell>
          </ng-container>

          <mat-header-row class="table-header-font" *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>
    </div>
    <img src="/assets/2.0/AVA_close_icon.svg"
      style="z-index: 1; height: 50px; position:relative; margin: -7% -20% 1% -5%; cursor:pointer;"
      (click)="closePopup()" />
  </div>

  <div fxLayout="row" fxLayoutAlign="center" *ngIf="showAddCoursePopup" class="lpopup"
    [ngClass.lt-lg]="'popup-smallScreen'">
    <div fxLayout="column" fxLayoutGap="10%" fxFlex="99">
      <span class="med-heading-text-config" style="margin-right:auto;">Enter course details</span>
      <form [formGroup]="courseForm" (ngSubmit)="addCourse()" validate fxLayout="column">
        <mat-label class="non-floating-label"> Course Name </mat-label>
        <mat-form-field floatLabel="always">
          <div class="input-field">
            <input class="input-text" matInput type="text" formControlName="courseName">
          </div>
        </mat-form-field>
        <mat-label class="non-floating-label"> Cover Image <br> <span style="font-size: medium;">512 x 390px (min
            size)</span></mat-label><br>
        <div class="image-upload-container" fxLayout="column" fxLayoutAlign="center center">
          <img *ngIf="selectedImage" [src]="selectedImage" alt="Selected Image"
            style="padding: 1%; max-width: 100%; max-height: 100%;">
          <label *ngIf="!selectedImage" for="fileInput" class="file-upload-label">
            Choose file on your device
          </label>
          <label *ngIf="selectedImage" for="fileInput" class="file-upload-label">
            Replace Image
          </label>
          <input type="file" accept="image/*" (change)="onFileSelected($event)" id="fileInput" class="input-file">
          <span class="image-name" *ngIf="selectedFileName">{{ selectedFileName }}</span>
        </div>
        <span class="error-message" *ngIf="existsError"> {{errorToShow}} </span>

        <button class="button" mat-raised-button type="submit" [disabled]="this.courseForm.invalid">SAVE
          CHANGES</button>
      </form>
    </div>
    <img src="/assets/2.0/AVA_close_icon.svg"
      style="z-index: 1; height: 50px; position:relative; margin: -9% -23% 1% -5%; cursor:pointer;"
      (click)="closePopup()">
  </div>

  <div fxLayout="row" fxLayoutAlign="center" *ngIf="showAssignCoursePopup" class="lpopup" style="height:600px;"
    [ngClass.lt-lg]="'popup-smallScreen'">
    <div fxLayout="column" fxLayoutGap="10%" fxFlex="99">
      <span class="med-heading-text-config" style="margin-right:auto;">Assign courses</span>
      <mat-label class="non-floating-label">Select Course</mat-label>
        <div fxLayout="column" fxLayoutGap="20px" class="course-list">
          <mat-checkbox *ngFor="let course of availableCourses" (change)="toggleCourseSelection(course, $event.checked)"
            color="primary">
            {{ course.Name }}
          </mat-checkbox>
        </div><br>
        <button class="button" mat-raised-button (click)="assignCourses()">SAVE CHANGES</button>
    </div>
    <img src="/assets/2.0/AVA_close_icon.svg"
      style="z-index: 1; height: 50px; position:relative; margin: -9% -23% 1% -5%; cursor:pointer;"
      (click)="closePopup()">
  </div>

  <div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between center" style="margin: 1% 20% 0% 10%;"
      fxLayoutAlign.lt-md="space-between center">
      <div fxLayout="column" fxFlex="80">
        <div class="med-heading-text-config" [style.opacity]="fadeOut?'.2':null">{{clientFullName}}</div>
        <div class="med-text-config" [style.opacity]="fadeOut?'.2':null">Summary of Training to date</div>
      </div>
      <div fxLayout="row" fxFlex="20" fxLayoutAlign="space-around center" *ngIf="clientName != 'VIS'"
        [style.opacity]="fadeOut?'.2':null">
        <mat-form-field floatLabel="never" fxFlex.lt-md="98" class="dropdown">
          <mat-icon class="add-icon" style="line-height: 15px;">add</mat-icon>
          <mat-label style="font-family: 'Libre Franklin'; color:white; padding: 15% 1% 1% 30%;"
            [ngClass]="selectedValue?'hidden':''">ADMIN</mat-label>
          <mat-select [(value)]="selectedValue" (selectionChange)="dropdownOptions($event.value)">
            <mat-option value="add" [ngClass]="(selectedValue=='add')?'dropdown-selected-text':''">Add
              Administrator</mat-option>
            <mat-option value="view">View Administrator</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="stretch" flex fxLayout.lt-lg="column" [style.opacity]="fadeOut?'.2':null"
      [ngClass]="fadeOut? 'main-container pointer-events': 'main-container'" fxLayoutGap="2%">

      <div class="sub-container" fxFlex="100" fxLayout="column" fxFlexAlign="stretch">
        <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="start stretch">
          <mat-card class="card-config" fxFlex="20%" [ngClass.lt-md]="'card-column'" fxFlex.md="40%">
            <mat-card-title class="card-heading-config" [ngClass.lt-md]="'card-column-heading'">
              <img src="assets\2.0\error_icon.svg" style="height: 30px; vertical-align: top;"> &nbsp;
              <span>Errors</span>
            </mat-card-title>
            <div *ngIf="isSessionLoading" style="margin-left:5%; margin-top: 12%;">
              <app-skeleton-loading Cwidth="230" Cheight="115">
              </app-skeleton-loading>
            </div>
            <mat-card-content class="card-content-config"
              [ngClass.lt-md]="'card-column-content'">{{errors}}</mat-card-content>
            <span class="card-secondary-content-config" style="margin:-50% 0% 0% 0%">errors caught</span>
          </mat-card>
          <mat-card class="card-config" fxFlex="20%" [ngClass.lt-md]="'card-column'" fxFlex.md="40%">
            <mat-card-title class="card-heading-config" [ngClass.lt-md]="'card-column-heading'">
              <img src="/assets/icon/menu_trainees_icon.svg" style="height: 30px; vertical-align: top;"> &nbsp;
              <span>Users</span>
            </mat-card-title>
            <div *ngIf="isSessionLoading" style="margin-left:5%; margin-top: 12%;">
              <app-skeleton-loading Cwidth="230" Cheight="115">
              </app-skeleton-loading>
            </div>
            <mat-card-content class="card-content-config"
              [ngClass.lt-md]="'card-column-content'">{{users}}</mat-card-content>
            <span class="card-secondary-content-config" style="margin:-50% 0% 0% 0%">unique users</span>
          </mat-card>
          <mat-card class="card-config" fxFlex="20%" [ngClass.lt-lg]="'card-column'" fxFlex.md="40%">
            <mat-card-title class="card-heading-config" [ngClass.lt-lg]="'card-column-heading'">
              <img src="/assets/icon/dashboard_sessions_icon.svg" style="height: 30px; vertical-align: top;"> &nbsp;
              <span>Sessions</span>
            </mat-card-title>
            <div *ngIf="isSessionLoading" style="margin-left:5%; margin-top: 12%;">
              <app-skeleton-loading Cwidth="230" Cheight="115">
              </app-skeleton-loading>
            </div>
            <mat-card-content class="card-content-config"
              [ngClass.lt-md]="'card-column-content'">{{totalSessions}}</mat-card-content>
            <span class="card-secondary-content-config" style="margin:-50% 0% 0% 0%">training sessions</span>
            <mat-card-content class="card-secondary-content-config" [ngClass.lt-md]="'card-column-secondary-content'">
            </mat-card-content>
          </mat-card>
          <mat-card class="card-config" fxFlex="20%" [ngClass.lt-md]="'card-column'" fxFlex.md="40%">
            <mat-card-title class="card-heading-config" [ngClass.lt-md]="'card-column-heading'">
              <img src="/assets/2.0/duration.svg" style="height: 30px; vertical-align: top;"> &nbsp;
              <span>Duration</span>
            </mat-card-title>
            <div *ngIf="isSessionLoading" style="margin-left:5%; margin-top: 12%;">
              <app-skeleton-loading Cwidth="230" Cheight="115">
              </app-skeleton-loading>
            </div>
            <mat-card-content class="card-time-content-config"
              [ngClass.lt-md]="'card-column-content'">{{totalTime}}</mat-card-content>
            <span class="card-secondary-content-config" style="margin:-50% 0% 0% 0%">training duration</span>
          </mat-card>
        </div>
      </div>
      <div class="sub-container" fxLayout="row" fxLayoutGap="-8%">
        <div class="med-heading-text-config" fxFlex="80%"> Courses</div>
        <mat-form-field floatLabel="never" fxFlex.lt-md="98" class="dropdown" *ngIf="isSARole()">
          <mat-icon class="add-icon" style="line-height: 15px;">add</mat-icon>
          <mat-label style="font-family: 'Libre Franklin'; color:white; padding: 15% 1% 1% 30%;"
            [ngClass]="courseSelectedValue?'hidden':''">COURSE</mat-label>
          <mat-select [(value)]="courseSelectedValue" (selectionChange)="courseDropdownOptions($event.value)">
            <mat-option value="add" [ngClass]="(courseSelectedValue=='add')?'dropdown-selected-text':''">Add Course</mat-option>
            <mat-option value="assign">Assign Course</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="sub-container" fxFlex="100" fxFlexAlign="stretch">
        <div [ngClass]="fadeButton? 'course-table-config-edit': 'course-table-config'">
          <mat-table [dataSource]="dataSourceCourse" matSort>
            <ng-container matColumnDef="Name">
              <mat-header-cell class="table-header-font" *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
              <mat-cell style="border-left: 2px solid #707070;" class="table-data-font" *matCellDef="let row">
                {{row.Name}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Sessions">
              <mat-header-cell class="table-header-font" *matHeaderCellDef> Sessions </mat-header-cell>
              <mat-cell class="table-data-font" *matCellDef="let row"> {{row.Sessions}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Users">
              <mat-header-cell class="table-header-font" *matHeaderCellDef>Users</mat-header-cell>
              <mat-cell class="table-data-font" *matCellDef="let row"> {{row.Users}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Duration">
              <mat-header-cell class="table-header-font" *matHeaderCellDef>
                <img src="/assets/icon/Timer_icon.svg" style="margin-left: 40px; height: 30px;">
              </mat-header-cell>
              <mat-cell class="table-data-font" *matCellDef="let row"> {{row.Duration}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Operations">
              <mat-header-cell class="table-header-font" *matHeaderCellDef> &nbsp;&nbsp;&nbsp;&nbsp; Operations
              </mat-header-cell>
              <mat-cell class="table-data-font-colored" *matCellDef="let row">
                <div class="button-group">
                  <button mat-button type="button" (click)="editCourse(row);"
                    [disabled]="!isSARole() || isDeleteEnabled">
                    <mat-icon matTooltip="Edit">create</mat-icon>
                  </button>
                  <button mat-button type="button" (click)="deleteCourse(row);" [disabled]="!isSARole()">
                    <mat-icon matTooltip="Delete">delete</mat-icon>
                  </button>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row class="table-header-font" *matHeaderRowDef="displayedColumnsCourse"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsCourse;"
              (click)="(!isSARole()||isDeleteEnabled)?$event.stopPropagation():editCourse(row)"></mat-row>
          </mat-table>
          <div *ngIf="isLoading" style="margin-left:0.5%; margin-top: 0.5%;">
            <div *ngFor="let _ of [].constructor(5)">
              <app-skeleton-loading Cwidth="1200" Cheight="48"><br><br>
              </app-skeleton-loading>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</app-main-nav>