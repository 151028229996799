import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  userRole: string;
  userType: string;
  constructor() { }

  public setRole(data) {
    this.userRole = data;
  }

  public getRole() {
    return this.userRole;
  }

  public setUserType(type){
    this.userType = type;
  }
  public getUserType() {
    return this.userType;
  }

  public clearCookies(){
    localStorage.removeItem('token');
    localStorage.removeItem('module');
    localStorage.removeItem('username');
    localStorage.removeItem('uid');
    localStorage.removeItem('userid');
    localStorage.removeItem('name');
    localStorage.removeItem('client');
    localStorage.removeItem('gender');
    localStorage.removeItem('age');
    localStorage.removeItem('course');
    localStorage.removeItem('courseID');
    localStorage.removeItem('ep');
    localStorage.removeItem('moduleName');
  }

}
